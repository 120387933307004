import { TLoginSignupResponse } from 'network/rest/auth/LoginSignup';
import { TLogoutResponse } from 'network/rest/auth/logout';

export const AUTH_ALIAS = 'authAlias';
export const REFRESH_TOKEN = 'refreshToken';

export interface IErrorLoginSignup {
  statusCode: number;
  error: string;
  message: string;
}

export interface IAuthState {
  isAuth: boolean;
  loading: boolean;
  response: TLoginSignupResponse | TLogoutResponse | null;
  error: IErrorLoginSignup | null;
  accessToken: string | null;
  refreshToken: string | null;
  userID: number | null;
  userId: number | null;
  resetToken: string | null;
  email: any | null;
}

export interface IGetUserEmailResponse {
  userId: number,
  resetToken: string,
  email: any,
}

export interface IGetUserEmailRequest {
  email: string
}

export interface IGetUserPasswordRequest {
  password: string,
  userId: string | undefined,
  resetToken: string | undefined,
}
