import { notification } from 'antd';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { mockFetch } from 'helpers/mockFetch';

import { BAR_CHART_ALIAS, IBarChartResponse } from './types';

const mockData: IBarChartResponse = {
  data: [30, 40, 45, 50, 49, 60, 70, 91],
  categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
};

export const getBarChartDataAction = createAsyncThunk<
  IBarChartResponse,
  string,
  {
    rejectValue: Error;
  }
>(`${BAR_CHART_ALIAS}/data`, async (payload, { rejectWithValue, getState }) => {
  try {
    // Тут можем задиспатчить други запросы или получить что то из стора
    // Например получим категории для графика для примера
    // const categories = getBarChartCategories(getState() as RootState);

    // Это пример работы с асихронными запросами
    // const examplePayload: IExampleGetRequest = {id: 'some id', search: 'search is optional'};
    // const { data } = await barChartDataRequest(examplePayload);
    // return data.data

    // Тут эмитирую работу с сервером
    const response = await mockFetch<IBarChartResponse>(mockData, 1000);
    return response as IBarChartResponse;
  } catch (error) {
    notification.error({ message: error.message });
    return rejectWithValue(new Error('Что то пошло не так'));
  }
});
