import { createAsyncThunk } from '@reduxjs/toolkit';
import { setConfigToken } from 'network/instance/config';
import {
  ILoginRequest,
  ILoginSignupResponseOK,
  ISignupRequest,
  logInRequest,
  signUpRequest,
  TLoginSignupResponse,
} from 'network/rest/auth/LoginSignup';
import {
  ILogoutResponseError,
  logoutRequest,
  TLogoutResponse,
} from 'network/rest/auth/logout';
import {
  IRefreshTokenRequest,
  IRefreshTokenResponseOK,
  refreshTokensRequest,
  TRefreshTokenResponse,
} from 'network/rest/auth/refreshTokens';

import {
  AUTH_ALIAS,
  IErrorLoginSignup, IGetUserEmailRequest,
  IGetUserEmailResponse, IGetUserPasswordRequest,
  REFRESH_TOKEN,
} from './types';
import { customAxiosUser } from '../../network/instance/castomAxios';

export const refreshTokenAction = createAsyncThunk<
  TRefreshTokenResponse,
  IRefreshTokenRequest,
  {
    rejectValue: IErrorLoginSignup;
  }
>(`${AUTH_ALIAS}/refresh`, async (payload, { rejectWithValue }) => {
  try {
    const response = await refreshTokensRequest(payload);
    const data = response.data as IRefreshTokenResponseOK;

    if (response.status === 200) {
      setConfigToken(String(data.access_token));
      localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
      return data;
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});

export const getLoginAction = createAsyncThunk<
  TLoginSignupResponse,
  ILoginRequest,
  {
    rejectValue: IErrorLoginSignup;
  }
>(`${AUTH_ALIAS}/login`, async (payload, { rejectWithValue }) => {
  try {
    const response = await logInRequest(payload);
    const data = response.data as ILoginSignupResponseOK;

    if (response.status === 200) {
      setConfigToken(String(data.access_token));
      localStorage.setItem(REFRESH_TOKEN, data.refresh_token!);
      return { ...response.data, statusCode: response.status };
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});

export const getSignupAction = createAsyncThunk<
  TLoginSignupResponse,
  ISignupRequest,
  {
    rejectValue: IErrorLoginSignup;
  }
>(`${AUTH_ALIAS}/signup`, async (payload, { rejectWithValue }) => {
  try {
    const response = await signUpRequest(payload);
    const data = response.data as ILoginSignupResponseOK;

    if (response.status === 200) {
      setConfigToken(String(data.access_token));
      localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
      return { ...response.data, statusCode: response.status };
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});

export const logoutAction = createAsyncThunk<
  TLogoutResponse,
  {},
  {
    rejectValue: ILogoutResponseError;
  }
>(`${AUTH_ALIAS}/logout`, async (_payload, { rejectWithValue }) => {
  try {
    const response = await logoutRequest();

    if (response.status === 204) {
      setConfigToken(null);
      localStorage.removeItem(REFRESH_TOKEN);
      return { ...response.data, statusCode: response.status };
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});

export const postUserEmail = createAsyncThunk<
  IGetUserEmailResponse,
  IGetUserEmailRequest,
  {
    rejectValue: ILogoutResponseError;
  }
>(`${AUTH_ALIAS}/getUserEmail`, async (payload, {rejectWithValue}) => {
    try {
      const response = await customAxiosUser.post(`/changePassword`, payload)
      if (response.status === 200) {
        return {...response.data, email: payload}
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
)

export const postUserPassword = createAsyncThunk<
  {},
  IGetUserPasswordRequest,
  {
    rejectValue: ILogoutResponseError;
  }
>(`${AUTH_ALIAS}/getUserPassword`, async (payload, {rejectWithValue}) => {
  const {password, userId, resetToken} = payload

  try {
      const response = await customAxiosUser.post(`/change_password/${userId}/${resetToken}`, { password })
      if (response.status === 200) {
        return {...response}
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
)
