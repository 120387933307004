export const USER_ALIAS = 'userAlias' as const;

export interface IUserStore {
  loading: boolean;
  error: IRequestError | null;
  name: string | null;
  email: string | null;
}

export interface IRequestError {
  statusCode: number;
  error: string;
  message: string;
}
