import axios from 'axios';
import { getBaseUrl } from './config';

export const customAxiosImages = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'image/jpeg',
  },
});

export const customAxiosUser = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
});
