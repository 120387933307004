import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';

import {
  addChoiceRequest,
  IAddChoiceRequest,
} from 'network/rest/presentationEdit/addChoice';
import {
  addSlideRequest,
  IAddSlideRequest,
} from 'network/rest/presentationEdit/addSlide';
import {
  deleteChoiceRequest,
  IDeleteChoiceRequest,
} from 'network/rest/presentationEdit/deleteChoice';
import {
  deleteSlideRequest,
  IDeleteSlideRequest,
} from 'network/rest/presentationEdit/deleteSlide';
import {
  duplicateSlideRequest,
  IDuplicateSlideRequest,
} from 'network/rest/presentationEdit/duplicateSlide';
import {
  getSlidesRequest,
  IGetSlidesRequest,
  IGetSlidesResponseOk,
} from 'network/rest/presentationEdit/getSlides';
import {
  IChoice,
  IResponseError,
  ISlide,
} from 'network/rest/presentationEdit/types';
import {
  IUpdateChoiceRequest,
  updateChoiceRequest,
} from 'network/rest/presentationEdit/updateChoice';
import {
  IUpdateSlideRequest,
  updateSlideRequest,
} from 'network/rest/presentationEdit/updateSlide';

import { EDIT_ALIAS } from './types';

export const getSlidesAction = createAsyncThunk<
  IGetSlidesResponseOk,
  IGetSlidesRequest,
  {
    rejectValue: IResponseError;
  }
>(`${EDIT_ALIAS}/getSlides`, async (payload, { rejectWithValue }) => {
  try {
    const response = await getSlidesRequest(payload);
    const data = response.data as IGetSlidesResponseOk;

    if (response.status === 200) {
      return { ...data, statusCode: response.status };
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    if (error.code === 'ERR_NETWORK') {
      return rejectWithValue({
        error: error.code,
        message: error.message,
        statusCode: 0,
      });
    }
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});

export const addSlideAction = createAsyncThunk<
  ISlide,
  IAddSlideRequest,
  {
    rejectValue: IResponseError;
  }
>(`${EDIT_ALIAS}/addSlide`, async (payload, { rejectWithValue }) => {
  try {
    const response = await addSlideRequest(payload);
    const data = response.data as ISlide;

    if (response.status === 200) {
      return { ...data, statusCode: response.status };
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    if (error.code === 'ERR_NETWORK') {
      return rejectWithValue({
        error: error.code,
        message: error.message,
        statusCode: 0,
      });
    }
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});

export const duplicateSlideAction = createAsyncThunk<
  ISlide,
  IDuplicateSlideRequest,
  {
    rejectValue: IResponseError;
  }
>(`${EDIT_ALIAS}/duplicateSlide`, async (payload, { rejectWithValue }) => {
  try {
    const response = await duplicateSlideRequest(payload);
    const data = response.data as ISlide;

    if (response.status === 200) {
      return { ...data, statusCode: response.status };
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    if (error.code === 'ERR_NETWORK') {
      return rejectWithValue({
        error: error.code,
        message: error.message,
        statusCode: 0,
      });
    }
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});

export const updateSlideAction = createAsyncThunk<
  ISlide[],
  IUpdateSlideRequest,
  {
    state: RootState;
    rejectValue: IResponseError;
  }
>(
  `${EDIT_ALIAS}/updateSlide`,
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await updateSlideRequest(payload);
      const data = response.data as ISlide;


      if (response.status === 200) {
        const presentation = getState().presentationEdit.response;
        if (presentation) {
          return presentation.slides.map((slide) => {
            if (slide.id === data.id) {
              return data;
            }
            return slide;
          });
        }
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        return rejectWithValue({
          error: error.code,
          message: error.message,
          statusCode: 0,
        });
      }
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

interface IDeleteSlideThunkOk {
  statusCode: number;
}
export const deleteSlideAction = createAsyncThunk<
  IDeleteSlideThunkOk,
  IDeleteSlideRequest,
  {
    rejectValue: IResponseError;
  }
>(`${EDIT_ALIAS}/deleteSlide`, async (payload, { rejectWithValue }) => {
  try {
    const response = await deleteSlideRequest(payload);

    if (response.status === 204) {
      return { statusCode: response.status };
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    if (error.code === 'ERR_NETWORK') {
      return rejectWithValue({
        error: error.code,
        message: error.message,
        statusCode: 0,
      });
    }
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});

export const addChoiceAction = createAsyncThunk<
  ISlide[],
  IAddChoiceRequest,
  {
    state: RootState;
    rejectValue: IResponseError;
  }
>(`${EDIT_ALIAS}/addChoice`, async (payload, { rejectWithValue, getState }) => {
  try {
    const response = await addChoiceRequest(payload);
    const data = response.data as IChoice;

    if (response.status === 200) {
      const presentation = getState().presentationEdit.response;
      if (presentation) {
        const currentSlides = presentation.slides;

        const newSlides = currentSlides.map((slide) => {
          if (slide.id === payload.slideId) {
            const newChoices = [...slide.choices, data];
            return { ...slide, choices: newChoices };
          }

          return slide;
        });
        return newSlides;
      }

      // return { ...data, statusCode: response.status };
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    if (error.code === 'ERR_NETWORK') {
      return rejectWithValue({
        error: error.code,
        message: error.message,
        statusCode: 0,
      });
    }
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});

export const updateChoiceAction = createAsyncThunk<
  ISlide[],
  IUpdateChoiceRequest,
  { state: RootState; rejectValue: IResponseError }
>(
  `${EDIT_ALIAS}/updateChoice`,
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await updateChoiceRequest(payload);
      const data = response.data as IChoice;

      if (response.status === 200) {
        const presentation = getState().presentationEdit.response;
        if (presentation) {
          const currentSlides = presentation.slides;

          const newSlides = currentSlides.map((slide) => {
            const newChoices = slide.choices.map((choice) => {
              if (choice.id === data.id) {
                return { ...choice, ...data };
              }

              return choice;
            });

            return { ...slide, choices: newChoices };
          });

          return newSlides;
        }

        // return { ...data, statusCode: response.status };
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        return rejectWithValue({
          error: error.code,
          message: error.message,
          statusCode: 0,
        });
      }
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);

interface IDeleteChoiceThunkOk {
  statusCode: number;
}
export const deleteChoiceAction = createAsyncThunk<
  IDeleteChoiceThunkOk,
  IDeleteChoiceRequest,
  {
    rejectValue: IResponseError;
  }
>(`${EDIT_ALIAS}/deleteChoice`, async (payload, { rejectWithValue }) => {
  try {
    const response = await deleteChoiceRequest(payload);

    if (response.status === 204) {
      return { statusCode: response.status };
    }
    return rejectWithValue({
      statusCode: response.status,
      error: `Что-то пошло не так. Ошибка ${response.status}`,
      message: `Что-то пошло не так. Ошибка ${response.status}`,
    });
  } catch (error) {
    if (error.code === 'ERR_NETWORK') {
      return rejectWithValue({
        error: error.code,
        message: error.message,
        statusCode: 0,
      });
    }
    return rejectWithValue({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
});
