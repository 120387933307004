import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import sortByOrder from 'helpers/sortByOrder';
import { ELayoutTypes, ESlideTypes } from 'network/rest/commonTypes';
import {
  getPresentationAction,
  getUserVoteAction,
  sendUserVoteAction,
} from './thunk';
import {
  EStatus,
  IUserDemoSessionState,
  PaceDTO,
  USER_DEMO_SESSION_ALIAS,
} from './types';

const initialState: IUserDemoSessionState | PaceDTO = {
  websocketStatus: { kind: 'noConnection' },
  loadingState: { kind: 'done' },
  demonstrationStatus: EStatus.Idle,
  answeredSlideIds: [],
  respondentKey: null,
  currentVote: {},
};

export const userDemoSessionSlice = createSlice({
  name: USER_DEMO_SESSION_ALIAS,
  initialState,
  reducers: {
    startConnection: (state) => {
      state.websocketStatus = { kind: 'connecting' };
    },
    connectionEstablished: (state) => {
      state.websocketStatus = { kind: 'connected' };
    },
    setCode: (state, action: PayloadAction<number>) => {
      state.code = action.payload;
    },
    setDemoStatus: (state, action: PayloadAction<EStatus>) => {
      state.demonstrationStatus = action.payload;
    },
    setCurrentSlideId: (state, action: PayloadAction<number>) => {
      state.currentSlideId = action.payload;
      state.currentVote = {};
    },
    closeConnection: (state) => {
      state.respondentKey = null;
      state.websocketStatus = { kind: 'noConnection' };
    },
    setVote: (
      state,
      action: PayloadAction<{ choiceId: number; value: number }>
    ) => {
      const { choiceId, value } = action.payload;
      state.currentVote[choiceId] = value;
    },
    clearState: () => initialState,
    clearPresentation: (state) => {
      state.presentation = undefined;
    },
  },
  extraReducers: (builder) => {
    // getPresentationAction
    builder.addCase(getPresentationAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(getPresentationAction.rejected, (state, { payload }) => {
      state.loadingState = { kind: 'error', error: payload! };
    });
    builder.addCase(getPresentationAction.fulfilled, (state, { payload }) => {
      state.loadingState = { kind: 'done' };
      state.respondentKey = payload.respondentKey;

      state.presentation = {};

      const { presentation } = payload;
      for (let i = 0; i < presentation.slides.length; i += 1) {
        const slide = presentation.slides[i];

        if (
          slide.type === ESlideTypes.Paragraph &&
          (slide.layout === ELayoutTypes.Left ||
            slide.layout === ELayoutTypes.Center)
        ) {
          state.presentation[slide.id] = {
            kind: 'paragraph',
            title: slide.heading,
            text: slide.description,
            layout: slide.layout,
            image: slide.image,
          };
        } else if (
          slide.type === ESlideTypes.OpenQuestions &&
          (slide.layout === ELayoutTypes.Left ||
            slide.layout === ELayoutTypes.Center)
        ) {
          state.presentation[slide.id] = {
            kind: 'open-answer',
            heading: slide.heading,
            layout: slide.layout,
          };
        } else if (
          slide.type === ESlideTypes.WordCloud &&
          (slide.layout === ELayoutTypes.Left ||
            slide.layout === ELayoutTypes.Center)
        ) {
          state.presentation[slide.id] = {
            kind: 'word-cloud',
            heading: slide.heading,
            layout: slide.layout,
          };
        } else if (slide.type === ESlideTypes.MultipleChoice) {
          state.presentation[slide.id] = {
            kind: 'multipleChoice',
            title: slide.heading,
            choices: sortByOrder(slide.choices).map((rawChoice) => {
              return {
                id: rawChoice.id,
                label: rawChoice.label,
              };
            }),
            image: slide.image,
            selectionType: slide.selectionType,
          };
        } else if (slide.type === ESlideTypes.Scales) {
          state.presentation[slide.id] = {
            kind: 'scale',
            title: slide.heading,
            min: {
              value: slide.range.min,
              label: slide.range.labels[0],
            },
            max: {
              value: slide.range.max,
              label: slide.range.labels[1],
            },
            scales: sortByOrder(slide.choices).map((rawScale) => {
              return {
                id: rawScale.id,
                label: rawScale.label,
              };
            }),
          };
        } else if (slide.type === ESlideTypes.Empty) {
          state.presentation[slide.id] = { kind: 'empty' };
        }
      }
    });

    // sendUserVote
    builder.addCase(sendUserVoteAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(sendUserVoteAction.rejected, (state, { payload }) => {
      if (payload?.statusCode === 403) {
        state.loadingState = { kind: 'done' };
        state.demonstrationStatus = EStatus.AlreadyVoted;
      } else {
        state.loadingState = { kind: 'error', error: payload! };
      }
    });
    builder.addCase(sendUserVoteAction.fulfilled, (state) => {
      state.loadingState = { kind: 'done' };
      state.demonstrationStatus = EStatus.JustVoted;
    });

    // getUserVote
    builder.addCase(getUserVoteAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(getUserVoteAction.rejected, (state, { payload }) => {
      state.loadingState = { kind: 'error', error: payload! };
    });
    builder.addCase(getUserVoteAction.fulfilled, (state, { payload }) => {
      state.loadingState = { kind: 'done' };

      if (payload.alreadyVoted) {
        state.demonstrationStatus = EStatus.AlreadyVoted;
      }
    });
  },
});

export const {
  startConnection,
  connectionEstablished,
  closeConnection,
  setCode,
  setDemoStatus,
  setCurrentSlideId,
  setVote,
  clearState,
  clearPresentation,
} = userDemoSessionSlice.actions;

export default userDemoSessionSlice.reducer;
