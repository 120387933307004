import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Skeleton } from 'antd';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getAuthState } from 'store/auth/selectors';
import { refreshTokenAction } from 'store/auth/thunk';

interface IAuthRequiredProps {
  children?: React.ReactNode;
}

const AuthRequired = (props: IAuthRequiredProps) => {
  const { children } = props;

  const [isHasAccess, setIsHasAccess] = useState(false);
  const [isRepeatRequest, setIsRepeatRequest] = useState(false);
  const { isAuth, refreshToken, loading } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      setIsHasAccess(true);
    }

    if (!isAuth && refreshToken && !isRepeatRequest && !loading) {
      setIsRepeatRequest(true);
      dispatch(refreshTokenAction({ refresh_token: refreshToken }));
    }

    if ((!loading && isRepeatRequest && !isAuth) || !refreshToken) {
      navigate('/login');
    }
  }, [isAuth, loading]);

  if (!isHasAccess) {
    return <Skeleton active />;
  }

  return <>{children}</>;
};

export default AuthRequired;
