import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';

export interface ILogoutResponseOK {
  statusCode: number;
  message: string;
  error: never;
}

export interface ILogoutResponseError {
  statusCode: number;
  error: string;
  message: string;
}

export type TLogoutResponse = ILogoutResponseOK | ILogoutResponseError;

export const logoutRequest = (): TResponse<TLogoutResponse> => {
  return makeRequest({
    url: '/user/logout',
    method: 'POST',
  });
};
