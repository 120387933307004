/* eslint no-lonely-if: 0 */

import { Client } from '@hapi/nes/lib/client';
import { baseHostName } from 'network/instance/types';
import { useEffect, useRef, useState } from 'react';
import {
  setDemoUsersCount,
  setSlideResultsVotes,
  startConnectionCreator,
  setSlideResultsAnswers,
} from 'store/creatorDemoSession/slice';
import { useAppSelector } from 'hooks/redux';
import {
  clearPresentation,
  closeConnection,
  connectionEstablished,
  setCurrentSlideId,
  setDemoStatus,
  startConnection,
} from './slice';
import { EStatus, PaceDTO } from './types';
import { getPresentationAction } from './thunk';

let lengthAnswers = 0;

const handler = (store) => (message: PaceDTO, _) => {
  if (typeof message === 'string') {
    store.dispatch(setDemoStatus(EStatus.NoDemonstration));
  } else {
    const { demonstration, slide, usersCount, results } = message;
    if (!demonstration.live) {
      store.dispatch(setDemoStatus(EStatus.NoDemonstration));
    } else {
      store.dispatch(setDemoUsersCount(usersCount));

      if ('choices' in results) {
        store.dispatch(setSlideResultsVotes(results));
      } else {
        if (lengthAnswers !== results.answers.length) {
          store.dispatch(setSlideResultsAnswers(results));
          lengthAnswers = results.answers.length;
        }
      }

      if (
        store.getState().userDemoSession.demonstrationStatus ===
        EStatus.NoDemonstration
      ) {
        // презентация началась
        store.dispatch(clearPresentation());
        store.dispatch(
          getPresentationAction({
            code: store.getState().userDemoSession.code,
          })
        );
      }

      if (store.getState().userDemoSession.currentSlideId !== slide.id) {
        store.dispatch(setCurrentSlideId(slide.id));
        if (!slide.enableVoting) {
          store.dispatch(setDemoStatus(EStatus.VotingClosed));
        } else {
          store.dispatch(setDemoStatus(EStatus.Idle));
        }
      } else {
        if (
          store.getState().userDemoSession.demonstrationStatus !==
          EStatus.JustVoted
        ) {
          if (!slide.enableVoting) {
            store.dispatch(setDemoStatus(EStatus.VotingClosed));
          } else {
            store.dispatch(setDemoStatus(EStatus.Idle));
          }
        }
      }
    }
  }
};

const socketMiddleware = () => {
  let client: Client | null = null;

  const onConnect = (store) => () => {
    store.dispatch(connectionEstablished());
  };

  return (store) => (next) => async (action) => {
    const codeUser = store.getState().userDemoSession.code;
    const codeCreator = store.getState().creatorDemoSession.connectionCode;

    if (startConnection.match(action) || startConnectionCreator.match(action)) {
      client = new Client(baseHostName);
      client.onConnect = onConnect(store);

      client.connect().then(async () => {
        client!
          .subscribe(`/demo/${codeUser || codeCreator}`, handler(store))
          .then(() => {});
      });
    } else if (closeConnection.match(action)) {
      client?.disconnect();
    }

    return next(action);
  };
};

export default socketMiddleware;
