import { createSlice } from '@reduxjs/toolkit';
import { IGetUserInfoOK } from 'network/rest/user/getUserInfo';
import { fetchUserInfoAction } from './thunk';
import { IUserStore, USER_ALIAS } from './types';

const initialState: IUserStore = {
  loading: false,
  error: null,
  name: null,
  email: null,
};

export const userSlice = createSlice({
  name: USER_ALIAS,
  initialState,
  reducers: {
    clearUserData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfoAction.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUserInfoAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addCase(fetchUserInfoAction.fulfilled, (state, { payload }) => {
      const { user } = payload as IGetUserInfoOK;
      state.loading = false;
      state.name = user.name;
      state.email = user.email;
    });
  },
});

export const { clearUserData } = userSlice.actions;

export default userSlice.reducer;
