import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IResponseError } from './types';

export interface IDeleteChoiceRequest {
  choiceId: number;
}

export type TDeleteChoiceResponse = {} | IResponseError;

export const deleteChoiceRequest = (
  requestParams: IDeleteChoiceRequest
): TResponse<TDeleteChoiceResponse> => {
  return makeRequest({
    url: `/choice/${requestParams.choiceId}`, // baseUrl подставиться сам,
    method: 'DELETE',
  });
};
