import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IResponseError } from './reusableTypes';

export interface IGetPresentationsResponseOK {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export type TGetPresentationsResponse =
  | IGetPresentationsResponseOK[]
  | IResponseError;

export const getPresentationsRequest =
  (): TResponse<TGetPresentationsResponse> => {
    return makeRequest({
      method: 'GET',
      url: '/presentation',
    });
  };
