import { TLoadingState } from 'store/commonTypes';
import { IPresentation } from 'network/rest/commonTypes';
import { TWebsocketStatus } from 'store/userDemoSession/types';

export type TCurrentSlide =
  | { kind: 'none'; id: number }
  | { kind: 'some'; id: number }
  | { kind: 'final'; id: number };

export interface IVoteResult {
  results: {
    choiceId: number;
    score: number;
    respondents: number;
  }[];
  respondents: number;
}

export interface IAnswersResult {
  results: {
    answer: string;
    answerCount: string;
    respondentCount: string;
  }[];
  respondents: number;
}

export type TDemoRunning = { kind: 'none' } | { kind: 'some'; value: boolean };

export interface ICreatorDemoSessionState {
  websocketStatus: TWebsocketStatus;
  demoId?: number;
  connectionCode?: number;
  loadingState: TLoadingState;
  demoRunning: TDemoRunning;
  usersCount: number;
  presentation?: {
    instance: IPresentation;
    slideOrder: number[];
  };
  currentSlide: TCurrentSlide;
  votes: { [id: number]: IVoteResult };
  votingStatus: { [id: number]: boolean }; // true - can vote; false - can't
  visibleVoting: boolean;
  answers: IAnswersResult[];
}

export const CREATOR_DEMO_SESSION_ALIAS = 'creatorDemoSessionAlias';

export interface pdfObjI {
  id: number;
  page: any;
}
