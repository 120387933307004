import React, { useEffect, useRef, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { Drawer, Layout } from 'antd';
import SideNav, { INavItem } from 'components/SideNav';
import UserProfile from 'components/UserProfile';

import { useWindowSize } from 'hooks/useWindowSize';

import { ROUTES } from 'constants/routes';
import LogoIcon from 'components/LogoIcon';

import { ReactComponent as PresentationsIcon } from './svg/presentations.svg';
import { ReactComponent as PatternsIcon } from './svg/templates.svg';
import { ReactComponent as HelpIcon } from './svg/help.svg';
import { ReactComponent as OpenSiderIcon } from './svg/mobile_sider.svg';
import { ReactComponent as CloseIcon } from './svg/close_icon.svg';
import { ReactComponent as ReportsIcon } from './svg/reports.svg';
import { ReactComponent as BasketvgIcon } from './svg/basket.svg';

import styles from './index.module.scss';

import logo_mobile from './svg/mobile_logo.svg';

type TPopupClick = MouseEvent & {
  path: Node[];
};

const MainPageLayout = () => {
  const [collapsedSider, setCollapsedSider] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const { pathname } = useLocation();

  const popupRef = useRef<HTMLDivElement>(null);

  const onCloseSider = () => {
    setCollapsedSider(false);
  };

  const openPopupHandler = () => {
    setPopupOpen(true);
  };

  const currentDisplayWidth = useWindowSize()[0];

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const myEvent = event as TPopupClick;
      const path = myEvent.path || (event.composedPath && event.composedPath());
      if (popupRef.current && !path.includes(popupRef.current)) {
        setPopupOpen(false);
      }
    };

    document.body.addEventListener('click', handleOutsideClick);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const upperMenuItems: INavItem[] = [
    {
      path: ROUTES.presentations.path,
      label: 'Мои презентации',
      icon: <PresentationsIcon />,
    },
    // {
    //   path: ROUTES.reports.path,
    //   label: 'Отчеты',
    //   icon: <ReportsIcon />,
    // },
    // {
    //   path: ROUTES.templates.path,
    //   label: 'Шаблоны',
    //   icon: <PatternsIcon />,
    // },
    // {
    //   path: ROUTES.basket.path,
    //   label: 'Корзина',
    //   icon: <BasketvgIcon />,
    // },
  ];

  // const lowerMenuItems: INavItem[] = [
  //   {
  //     path: ROUTES.helps.path,
  //     label: 'Помощь',
  //     icon: <HelpIcon />,
  //   },
  // ];

  // const navMenuItems = upperMenuItems.concat(lowerMenuItems);

  return currentDisplayWidth >= 768 ? (
    <div className={styles.layout}>
      <div className={styles.header}>
        <div className={styles.header_wrapper} ref={popupRef}>
          <Link to="/" className={styles.header_link}>
            <div className={styles.logo_block}>
              <div className={styles.logo_sign}>
                <LogoIcon />
              </div>
              <p className={styles.title}>YöReactions</p>
            </div>
          </Link>
          <button
            type="button"
            onClick={openPopupHandler}
            className={styles.open_popup_button}
          >
            <UserProfile />
            {popupOpen && (
              <div className={styles.logout_popup}>
                <div className={styles.logout_popup_wrapper}>
                  <UserProfile
                    showArrow={false}
                    showInfo={false}
                    showPic={false}
                    logout
                  />
                </div>
              </div>
            )}
          </button>
        </div>
      </div>
      <div className={styles.aside}>
        <SideNav
          upperItems={upperMenuItems}
          // lowerItems={lowerMenuItems}
          selectedKey={pathname}
        />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  ) : (
    <Layout className={styles.mobile_layout}>
      <div className={styles.mobile_header}>
        <div className={styles.mobile_header__middle}>
          <Link to="/">
            <img src={logo_mobile} alt="mobile logo" />
          </Link>
        </div>
        <div className={styles.mobile_header__right}>
          {React.createElement(OpenSiderIcon, {
            className: 'trigger',
            onClick: () => setCollapsedSider(!collapsedSider),
          })}
        </div>
      </div>
      <Outlet />
      <Drawer
        placement="right"
        open={collapsedSider}
        onClose={onCloseSider}
        className={styles.mobile_sider}
        width={267}
        closeIcon={<CloseIcon />}
      >
        <SideNav
          // upperItems={navMenuItems}
          selectedKey={pathname}
          arbitraryLowerComponents={[
            <div
              key="user-profile"
              className={styles.mobile_sider__user_profile_wrapper}
            >
              <UserProfile flipped small logout />
            </div>,
          ]}
          onClick={onCloseSider}
        />
      </Drawer>
    </Layout>
  );
};

export default MainPageLayout;
