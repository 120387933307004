import { createSlice } from '@reduxjs/toolkit';
import { ILoginSignupResponseOK } from 'network/rest/auth/LoginSignup';
import { IRefreshTokenResponseOK } from 'network/rest/auth/refreshTokens';

import {
  getLoginAction,
  getSignupAction,
  logoutAction, postUserEmail,
  refreshTokenAction,
} from './thunk';
import { AUTH_ALIAS, IAuthState, REFRESH_TOKEN } from './types';

const initialState: IAuthState = {
  isAuth: false,
  loading: false,
  error: null,
  response: null,
  accessToken: null,
  refreshToken: localStorage.getItem(REFRESH_TOKEN),
  userID: null,
  userId: null,
  resetToken: null,
  email: null,
};

export const authSlice = createSlice({
  name: AUTH_ALIAS,
  initialState,
  reducers: {
    setAuthTrue: (state) => {
      state.isAuth = true;
    },
    setAuthFalse: (state) => {
      state.isAuth = false;
    },
    clearAuthData: () => {
      return {
        ...initialState,
        refreshToken: localStorage.getItem(REFRESH_TOKEN),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshTokenAction.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
      state.isAuth = false;
    });
    builder.addCase(refreshTokenAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
      state.isAuth = false;
    });
    builder.addCase(refreshTokenAction.fulfilled, (state, { payload }) => {
      const { access_token, refresh_token, user } =
        payload as IRefreshTokenResponseOK;
      state.loading = false;
      state.isAuth = true;
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
      state.userID = user.id;
    });
    builder.addCase(getLoginAction.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getLoginAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addCase(getLoginAction.fulfilled, (state, { payload }) => {
      const { access_token, refresh_token, user } =
        payload as ILoginSignupResponseOK;
      state.loading = false;
      state.response = payload;
      state.isAuth = true;
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
      state.userID = user.id;
    });
    builder.addCase(getSignupAction.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getSignupAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addCase(getSignupAction.fulfilled, (state, { payload }) => {
      const { access_token, refresh_token, user } =
        payload as ILoginSignupResponseOK;
      state.loading = false;
      state.response = payload;
      state.isAuth = true;
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
      state.userID = user.id;
    });
    builder.addCase(logoutAction.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(logoutAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addCase(logoutAction.fulfilled, (state, { payload }) => {
      state.isAuth = false;
      state.loading = false;
      state.response = payload;
      state.accessToken = null;
      state.refreshToken = null;
      state.userID = null;
    });
    builder.addCase(postUserEmail.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
      state.email = null;
    });
    builder.addCase(postUserEmail.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addCase(postUserEmail.fulfilled, (state, { payload }) => {
      state.isAuth = false;
      state.loading = false;
      state.accessToken = null;
      state.resetToken = payload.resetToken
      state.refreshToken = null;
      state.userID = null;
      state.userId = payload.userId;
      state.email = payload.email.email
    });
  },
});

export const { setAuthTrue, setAuthFalse, clearAuthData } = authSlice.actions;

export default authSlice.reducer;
