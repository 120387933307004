import React from 'react';

export const SignUpPage = React.lazy(() => import('page/SignUp'));
export const LogInPage = React.lazy(() => import('page/LogIn'));
export const ForgetPasswordPage = React.lazy(() => import('page/ForgetPassword'))
export const AgainPasswordPage = React.lazy(() => import('page/AgainPassword'))

export const PresentationEditPage = React.lazy(
  () => import('page/PresentationEdit')
);
export const StartPage = React.lazy(() => import('page/Start'));
export const MainPage = React.lazy(() => import('page/Main'));
export const Page404 = React.lazy(() => import('page/Page404'));

export const PolicyPage = React.lazy(
  () => import('page/plugs_for_testing/Policy')
);
export const TermsUse = React.lazy(
  () => import('page/plugs_for_testing/TermsUse')
);
export const ChangePasswordPage = React.lazy(
  () => import('page/ChangePassword')
);

export const CreatorSessionViewer = React.lazy(
  () => import('page/CreatorSessionViewer')
);
export const UserSessionViewer = React.lazy(
  () => import('page/UserSessionViewer')
);

export const LandingPage = React.lazy(() => import('page/Landing'));
