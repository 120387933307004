import { IAddChoiceRequest } from 'network/rest/presentationEdit/addChoice';
import { IAddSlideRequest } from 'network/rest/presentationEdit/addSlide';
import { IDeleteChoiceRequest } from 'network/rest/presentationEdit/deleteChoice';
import { IDeleteSlideRequest } from 'network/rest/presentationEdit/deleteSlide';
import { IDuplicateSlideRequest } from 'network/rest/presentationEdit/duplicateSlide';
import { IGetSlidesResponseOk } from 'network/rest/presentationEdit/getSlides';
import { IResponseError } from 'network/rest/presentationEdit/types';
import { IUpdateChoiceRequest } from 'network/rest/presentationEdit/updateChoice';
import { IUpdateSlideRequest } from 'network/rest/presentationEdit/updateSlide';

export const EDIT_ALIAS = 'editAlias';

export interface IPresentationEditState {
  loading: boolean;
  error: IResponseError | null;
  response: IGetSlidesResponseOk | null;

  savingLoading: boolean;
  savingStatus: 'needUpdate' | null;
  savingAttempts: number;
  addStack: IAddSlideRequest[] | [];
  isSlideActionActive: boolean;
  duplicateStack: IDuplicateSlideRequest[] | [];
  updatingStack: IUpdateSlideRequest[] | [];
  deleteStack: IDeleteSlideRequest[] | [];
  addChoiceStack: IAddChoiceRequest[] | [];
  isChoiceActionActive: boolean;
  updatingChoiceStack: IUpdateChoiceRequest[] | [];
  deleteChoiceStack: IDeleteChoiceRequest[] | [];

  activeSlideNumber: number;
  dragedSlideId: null | number;
}
