import { IImage } from './presentationEdit/types';

export interface IResponseError {
  statusCode: number;
  error: string;
  message: string;
}

export interface IPresentation {
  id: number;
  name: string;
  code: number;
  theme: string;
  author: {
    id: number;
  };
  slides: ISlide[];
  created_at: string;
  updated_at: string;
}

export enum ESlideTypes {
  'Empty' = 'empty',
  'Paragraph' = 'paragraph',
  'MultipleChoice' = 'choices',
  'Scales' = 'scales',
  'OpenQuestions' = 'open-answer',
  'Big' = 'big',
  'Heading' = 'heading',
  'SelectAnswer' = 'select-answer',
  'WordCloud' = 'word-cloud',
}

export enum ELayoutTypes {
  'Center' = 'center',
  'Left' = 'left',
  'Right' = 'right',
}

export interface IChoice {
  id: number;
  order: number;
  label: string;
  image: string;
  correctAnswer: boolean;
  slide: {
    id: number;
  };
  created_at: string;
  updated_at: string;
}

export interface ISlide {
  id: number;
  presentation: {
    id: number;
    author: {
      id: number;
    };
  };
  order: number;
  type: ESlideTypes;
  heading: string;
  description: string;
  content: {};
  enableVoting: boolean;
  showResults: boolean;
  choices: IChoice[];
  range: {
    min: number;
    max: number;
    labels: string[] | [];
  };
  layout: ELayoutTypes;
  image: IImage[];
  created_at: string;
  updated_at: string;
  pageNumber?: number;
  statusCode?: number;
  selectionType?: boolean;
}
