import React from 'react';

const LogoIcon = () => {
  return (
    <svg
      width="79"
      height="52"
      viewBox="0 0 79 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 5C0.5 2.23858 2.73858 0 5.5 0H25.5C39.3071 0 50.5 11.1929 50.5 25H20.5C9.4543 25 0.5 16.0457 0.5 5Z" fill="#589356"
        />
        <path
          d="M50.5 52C50.5 38.1929 39.3071 27 25.5 27C11.6929 27 0.5 38.1929 0.5 52H50.5Z" fill="#F092FF"
        />
        <path
          d="M17.5 40V40C16.7677 38.2044 14.208 38.1946 13.5 40V40" stroke="#252525" strokeWidth="0.897301" strokeLinecap="round"
        />
        <path
          d="M37.5 40V40C36.7677 38.2044 34.208 38.1946 33.5 40V40" stroke="#252525" strokeWidth="0.897301" strokeLinecap="round"
        />
        <g clipPath="url(#clip0_1245_1883)">
          <path
            d="M29.5 42C29.5 44.2091 27.7091 46 25.5 46C23.2909 46 21.5 44.2091 21.5 42H29.5Z" fill="#252525"
          />
          <path
            d="M21.5 45.1668C21.5 46.2407 23.828 48.1809 25.5352 47.9864C27.6304 47.9864 29.4928 46.2363 29.4928 45.264C29.648 44.4861 27.2495 44 25.5352 44C23.8209 44 21.5 44.0928 21.5 45.1668Z" fill="#EF2A1F"
          />
        </g>
        <rect
          x="52.5"
          width="25"
          height="25"
          rx="4.09169"
          fill="#E26E01"
          />
          <g clipPath="url(#clip1_1245_1883)">
            <rect
              x="52.5"
              y="27"
              width="25"
              height="25"
              rx="12.5"
              fill="#90ACF0"
            />
            <rect
            x="54.4368"
            y="39.7866"
            width="15"
            height="15"
            rx="4.09169"
            transform="rotate(-45 54.4368 39.7866)"
            fill="#252525"
            />
          </g>
          <defs>
            <clipPath id="clip0_1245_1883">
              <path
                d="M29.5 42C29.5 44.2091 27.7091 46 25.5 46C23.2909 46 21.5 44.2091 21.5 42H29.5Z" fill="white"
              />
            </clipPath>
            <clipPath id="clip1_1245_1883">
              <rect
                width="25"
                height="25"
                fill="white"
                transform="translate(52.5 27)"
              />
            </clipPath>
          </defs>
    </svg>
  );
};

export default LogoIcon;

