import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IResponseError, ISlide } from './types';

export interface IUpdateSlideRequest {
  id: number;
  order?: number;
  type?: string;
  heading?: string;
  description?: string;
  content?: {};
  enableVoting?: boolean;
  showResults?: boolean;
  range?: {
    min: number;
    max: number;
    labels: string[];
  };
  layout?: string;
  image?: string;
  selectionType?: boolean;
}

export type TUpdateSlideResponse = ISlide | IResponseError;

export const updateSlideRequest = (
  data: IUpdateSlideRequest
): TResponse<TUpdateSlideResponse> => {
  return makeRequest({
    url: `/slide`, // baseUrl подставиться сам,
    method: 'PUT',
    data,
  });
};
