export const BAR_CHART_ALIAS = 'barChartAlias' as const;

export interface IBarChartStore {
  loading: boolean;
  error: Error | null;
  dataSource: number[];
  categories: number[];
}

export type IBarChartResponse = {
  data: number[];
  categories: number[];
};
