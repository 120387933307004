import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IResponseError } from 'network/rest/commonTypes';

export interface IGetUserVoteRequest {
  slideId: number;
  respondentKey: string;
}

export interface IGetUserVoteResponseOk {
  canVote: boolean;
  alreadyVoted: boolean;
  choices: {
    choiceId: number;
    value: number;
  }[];
}

export type TGetUserVoteResponse = IGetUserVoteResponseOk | IResponseError;

export const getUserVote = (
  params: IGetUserVoteRequest
): TResponse<TGetUserVoteResponse> => {
  return makeRequest({
    withCredentials: true,
    // url: `/slide/${params.slideId}/vote?respondentKey=${params.respondentKey}`
    url: `/slide/${params.slideId}/vote`,
  });
};
