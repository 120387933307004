import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IPresentation, IResponseError } from './reusableTypes';

export interface IUpdatePresentationRequest {
  name: string;
  id: number;
}

export type TUpdatePresentationResponse = IPresentation | IResponseError;

export const updatePresentationRequest = (
  data: IUpdatePresentationRequest
): TResponse<TUpdatePresentationResponse> => {
  return makeRequest({
    method: 'PUT',
    url: `/presentation/${data.id}`,
    data: { name: data.name },
  });
};
